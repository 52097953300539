import React from "react";
import Temp from "./components/weather/temp";

function App() {
  return (
    <>
    <Temp/>

    </>
  );
}

export default App;
